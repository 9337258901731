
export function switchLanguage() {  //** Detect prefer language of browser
  const preferLanguage = navigator.language;

  var showLanguage = '';
  if (preferLanguage === 'zh-TW') {
    showLanguage = 'zhTW';
  } else {
    showLanguage = 'EN';
  }

  return showLanguage;
};