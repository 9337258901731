/*
  This project is created by Aurora Liu. You can visit https://profoundstars.com to contact the author. 
*/
import React from 'react';
import { createRoot } from 'react-dom/client';


import './index.css';
import content from './content.json';
import Adsense_one_Gomokuupperbar from './adsense';
import { switchLanguage } from './loginLanguage';

const language = switchLanguage();
const contentData = content['Gobang'];

const Board = (props) => { //** generate game board
  return (
    <button className={"square " + props.winnerBg} 
            onClick={ () => props.onClick(props.arrIndex) }>
      <div className={"chess " + props.color}></div>
    </button>
  );
}
/* 
rowNum = 6;

| 0| 1| 2| 3| 4| 5|
| 6| 7| 8| 9|10|11|
|12|13|14|15|16|17|
|18|19|20|21|22|23|
|24|25|26|27|28|29|
|30|31|32|33|34|35|

=> centerVal = [14, 15, 20, 21];
=> halfNumsOfDiagonalLines = 6 * 2 + 2 = 14 (index of the first 5X5 square) 
*/

const calculateWinnerLines = (rowNum) => {
  let i, j;
  let firstFiveXFiveSquareCenterVal = rowNum * 2 + 2; //** index number of the first 5X5 square on board (top-left hand)
  let halfNumsOfDiagonalLines = rowNum - 4; //** the half of total number diagonal lines on board, this number also means that total numbers of "center value" which in 5X5 squares are Math.pow(halfNumsOfDiagonalLines, 2); 
  let AllFiveXFiveSqureCenterValArr = [];
  let centerVal;  //** the "center Value" of 5X5 square

  //** loop into find out all 5X5 square center values. We have 'firstFiveXFiveSquareCenterVal' and 'halfNumsOfDiagonalLines' values, so we can use their pattern between each number to find all values and push them into "AllFiveXFiveSqureCenterValArr" array.
  for (i = 0; i < halfNumsOfDiagonalLines; i++) {
    centerVal = rowNum * i + firstFiveXFiveSquareCenterVal;
    for (j = 0; j < halfNumsOfDiagonalLines; j++) {
      AllFiveXFiveSqureCenterValArr.push(centerVal + j);
    }
  }

  //** loop into find out all possible win lines, 
  let CrossTopLeftCount = firstFiveXFiveSquareCenterVal / 2; //** the top-left cross's discrete number size
  let CrossTopRightCount = rowNum - 1; //** the top-left cross's discrete number size
  let topLtoBtmRLinesArr = [];  //** array of all diagonal lines from top-left to bottom-right
  let topRtoBtmLLinesArr = []; //** array of all diagonal lines from top-right to bottom-left

  for (centerVal of AllFiveXFiveSqureCenterValArr) {
    //** loop to find out the cross lines (diagonal line from top-left to bottom-right)
    let LeftCrossLineSet = [centerVal - CrossTopLeftCount * 2, centerVal - CrossTopLeftCount, centerVal, centerVal + CrossTopLeftCount, centerVal + CrossTopLeftCount * 2];

    //** loop to find out the cross lines (diagonal line from top-right to bottom-left)
    let RightCrossLineSet = [centerVal - CrossTopRightCount * 2, centerVal - CrossTopRightCount, centerVal, centerVal + CrossTopRightCount, centerVal + CrossTopRightCount * 2];

    topLtoBtmRLinesArr.push(LeftCrossLineSet);
    topRtoBtmLLinesArr.push(RightCrossLineSet);
  }


  //** looking for horizontal win lines
  let hLinesNum = rowNum - 5 + 1; //** one row has (rowNum - 5 + 1) win lines, and the total numbers of rows are 'rowNum'. 
  let hLinesArr = [];  //** array save all horizontal lines (2D array)

  for (i = 0; i <= rowNum * (rowNum - 1); i += rowNum) { //** loop to find the all horizontal win lines
    for (j = 0; j < hLinesNum; j++) {
      let hLinesSet = [i + j, i + j + 1, i + j + 2, i + j + 3, i + j + 4];
      hLinesArr.push(hLinesSet);
    }
  }

  //** looking for vertial win lines
  let vLinesNum = hLinesNum; //** one column has (rowNum - 5 + 1) win lines, and the total numbers of columns are 'rowNum'. 
  let vLinesArr = []; //** array save all vertical lines (2D array)

  for (i = 0; i < rowNum; i++) {  //** loop to find the all vertical win lines
    for (j = 0; j < vLinesNum; j++) {
      let vLinesSet = [i + j * rowNum, i + (j + 1) * rowNum, i + (j + 2) * rowNum, i + (j + 3) * rowNum, i + (j + 4) * rowNum];
      vLinesArr.push(vLinesSet);
    }
  }

  let allWinLines = topLtoBtmRLinesArr.concat(topRtoBtmLLinesArr).concat(hLinesArr).concat(vLinesArr);

  return allWinLines;
}

const findWinner = (allWinLines, updateValue) => {
  //** find the winner
  for (let i = 0; i < allWinLines.length; i++) {
    const [a, b, c, d, e] = allWinLines[i];
    if (updateValue[a] && updateValue[a] === updateValue[b] && updateValue[a] === updateValue[c] && updateValue[a] === updateValue[d] && updateValue[a] === updateValue[e]) {

      let winnerSymbol = updateValue[a];
      let winLine = [a, b, c, d, e];
      let foundOutWinner = [winnerSymbol, winLine];
      return foundOutWinner;
    }
  }
  
  return null;
}

class Game extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowNum: this.props.rowNum,
      squareValue: Array(Math.pow(this.props.rowNum, 2)).fill(null), //** [null, 1, 2, 3, 4, 5, 6, 7, 8, 9], 
      colorArr: Array(Math.pow(this.props.rowNum, 2)).fill(null),
      winnerBackground: Array(Math.pow(this.props.rowNum, 2)).fill(null),
      playerOneNext: true,
      playerTwoNext: false,
      winner: " ",
      nextPlayer: " ",
      nextPlayerColor: "playerOneColor",
      allWinLines: '',
      checkWinner: true,
      record: Array(10).fill(null),
      gameRoundNum: 0,
      competation: [0, 0],
      showLanguage: this.props.lang
    };

    this.generateRow = this.generateRow.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.resetGame = this.resetGame.bind(this);
    this.gameRecord = this.gameRecord.bind(this);
    this.resetRecord = this.resetRecord.bind(this);
    this.switchLanguageBtn = this.switchLanguageBtn.bind(this);
  }

  generateRow() {
    let boardDOM = [];
    let sqaureNum = Math.pow(this.state.rowNum, 2);
    for (let arrIndex = 0; arrIndex <= sqaureNum - 1; arrIndex++) {
      boardDOM.push(
        <Board key={"button-" + arrIndex.toString()} 
               arrIndex={ arrIndex } 
               color={ this.state.colorArr[arrIndex] } 
               onClick={ (arrIndex) => this.handleClick(arrIndex, this.state.rowNum) } 
               winnerBg={ this.state.winnerBackground[arrIndex] } />);

      if ((arrIndex + 1) % this.state.rowNum === 0) { //** if arrIndex is a mulitiple of number of column set it to next row
        boardDOM.push(
          <br key={"row-" + ((arrIndex + 1) / this.state.rowNum).toString()}    
              className="clear-fix" />);
      }
    }
    return boardDOM; //** output array will be composed of "<button>" and "<br>"
  }

  componentDidMount() { //** (invoked only once time) invoked after component has been added to VirtualDOM and has been rendered
    this.setState({
      allWinLines: calculateWinnerLines(this.props.rowNum)
    })
  }

  handleClick(arrIndex) {
    let updateValue = [...this.state.squareValue]; //creates the clone of the state
    let updateColorArr = [...this.state.colorArr];
    let updateWinnerBackground = [...this.state.winnerBackground];
    let playerOneNext, playerTwoNext, nextPlayer, updateNextPlayerColor;

    if ((this.state.playerOneNext && this.state.squareValue[arrIndex] == null) && this.state.checkWinner) {
      /* ** handle playerOne clicked, to make sure it is palyerOne, the state will be (before clicked) 
        playerOneNext: true; squareValue[arrIndex] == null (means haven't clicked yet) */
      updateValue[arrIndex] = "X";  //** marked playerOne Clicked as "X"
      updateColorArr[arrIndex] = "playerOneColor";
      playerOneNext = false;  //** change the next player state
      playerTwoNext = true;
      nextPlayer = "O"; //** marked playerTwo Clicked as "O"
      updateNextPlayerColor = "playerTwoColor";

    } else if ((this.state.playerTwoNext && this.state.squareValue[arrIndex] == null) && this.state.checkWinner) {
      /* ** handle playerTwo clicked */
      updateValue[arrIndex] = "O";
      updateColorArr[arrIndex] = "playerTwoColor";
      playerOneNext = true;
      playerTwoNext = false;
      nextPlayer = "X";
      updateNextPlayerColor = "playerOneColor";
    } else if (this.state.squareValue[arrIndex] !== null) {
      /* ** if clicked on a button which already had chess, do nothing */
      return;
    }

    this.setState({
      squareValue: updateValue,   //** update new array to state array
      playerOneNext: playerOneNext,
      playerTwoNext: playerTwoNext,
      nextPlayer: nextPlayer,
      colorArr: updateColorArr,
      nextPlayerColor: updateNextPlayerColor
    });

    if (this.state.checkWinner && findWinner(this.state.allWinLines, updateValue)) { //** if findWinner doesn't return null;  //** check "this.state.checkWinner" first, in order to prevent excute findWinner constantly
      var [winSymble, winArr] = findWinner(this.state.allWinLines, updateValue);

      for (let winIndex of winArr) {
        updateWinnerBackground[winIndex] = "winnerColor";
      }
      
      if (winSymble === "X") {
        winSymble = "playerOneColor";
      } else if (winSymble === "O") {
        winSymble = "playerTwoColor";
      }

      this.setState({
        winner: winSymble,
        winnerBackground: updateWinnerBackground,
        checkWinner: false,
        nextPlayerColor: ''
      });

      this.gameRecord(winSymble);
    }
  }


  resetGame() {
    this.setState((preState, props) => ({
      rowNum: preState.rowNum, //** also --> rowNum: props.rowNum;
      squareValue: Array(Math.pow(props.rowNum, 2)).fill(null), //** [null, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      colorArr: Array(Math.pow(props.rowNum, 2)).fill(null),
      winnerBackground: Array(Math.pow(props.rowNum, 2)).fill(null),
      playerOneNext: true,
      playerTwoNext: false,
      winner: "",
      nextPlayer: " ",
      nextPlayerColor: "playerOneColor",
      checkWinner: true
    }));
  }

  gameRecord(winSymble) {
    if (winSymble === 'playerOneColor') {
      this.setState({
        competation: [this.state.competation[0] + 1, this.state.competation[1]]
      })
    } else if (winSymble === 'playerTwoColor') {
      this.setState({
        competation: [this.state.competation[0], this.state.competation[1] + 1]
      })
    }

    if (this.state.gameRoundNum < 10) {
      let recordUpdate = this.state.record;
      recordUpdate[this.state.gameRoundNum] = winSymble;
      this.setState({
        gameRoundNum: this.state.gameRoundNum + 1
      });
    } 

    if (this.state.gameRoundNum === 10) {
      let newRoundRecord = Array(10).fill(null);
      newRoundRecord[0] = winSymble;

      this.setState({
         record: newRoundRecord,
         gameRoundNum: 1,
         competation: winSymble === 'playerOneColor' ? [1, 0] : [0, 1]
      });
    }
  }

  resetRecord() {
    this.setState({
      record: Array(10).fill(null),
      gameRoundNum: 0,
      competation: [0, 0]
    });
  }

  
  switchLanguageBtn(switchLangTo) {
    if (switchLangTo === 'zhTW') {
      this.setState({
        showLanguage: 'zhTW'
      });
    } else if (switchLangTo === 'EN') {
      this.setState({
        showLanguage: 'EN'
      });
    }
  } 
  
 
  render() {
    return (
      <div>
        {/* Adsense_one_Gomokuupperbar() */}

        <div id="app" className={ this.props.condition }>          
          <div id="record-viewer">
            <div id="game-record-conculsion">
              <div>
                <div className = "chess playerOneColor" ></div><p>V.S.</p>< div className = "chess playerTwoColor" ></div >
              </div>
              <div>
                <div> { this.state.competation[0] } </div><p>:</p><div>{ this.state.competation[1] }</div>
              </div>
            </div>

            <div id="game-record">
              <ul id="roundResult" >
              { this.state.record.map((winner, i) => {
                  let winData = '';
                  if (winner == null) {
                    winner = '';
                    winData = 'notRecord';
                  } else {
                    winData = 'Recorded';
                  }

                  return ( 
                    <li key={"round" + i.toString() } className={ winData }>
                      <div className={"listNum " + winData}> {i + 1}. </div> <div className={ "chess " + winner + " " + winData}></div>
                    </li>    
                  );
              })}
              </ul>
            </div>
            <div>
              <button id="resetBtn" className="controller-button" onClick={ () => this.resetRecord() } >{ contentData[this.state.showLanguage]['record-viewer'].resetbtn }</button>
            </div>
          </div>

          <div id="game-viewer" className={ this.props.condition }>
            <div id="border" >
              { this.generateRow() }
            </div>
          </div>
          <div id="controller">
            <div id="switch-languages">
              <button id="lang-tch" className={this.state.showLanguage === 'zhTW' ? "lang-on" : "lang-off"} onClick={ () => this.switchLanguageBtn('zhTW') }>繁體中文</button>
              <button id="lang-en" className={this.state.showLanguage === 'EN' ? "lang-on" : "lang-off"} onClick={ () => this.switchLanguageBtn('EN') }>English</button>
            </div>
            <div id="round-viewer">
              <div><h3>{ contentData[this.state.showLanguage]['round-viewer'].winner }</h3><div className={ "chess " + this.state.winner }></div></div>
              <div><h3>{ contentData[this.state.showLanguage]['round-viewer'].next }</h3> <div className={ "chess " + this.state.nextPlayerColor }></div></div>
              <div>
                <button id="replayBtn" className="controller-button" onClick={ () => this.resetGame() } >{ contentData[this.state.showLanguage]['round-viewer'].replaybtn }</button>
              </div>
            </div>
          </div>

        </div>

        <div id="information">
          <div id="howTo">
            <h3 className={ this.state.showLanguage }>{ contentData[this.state.showLanguage].information.howToTitle }</h3>
            <p>{ contentData[this.state.showLanguage].information.howTo[0] }
            <span>{ contentData[this.state.showLanguage].information.howTo[1] }</span>
            <span>{ contentData[this.state.showLanguage].information.howTo[2] }</span>
            </p>
          </div>
          <div id="aboutAuthor">
            <h1 className = { this.state.showLanguage }> { contentData[this.state.showLanguage].information.aboutAuthorTitle }</h1>
            <p>{contentData[this.state.showLanguage].information.aboutAuthor[0]} <a href="https://profoundstars.com" target="_blank" rel="noopener noreferrer">{contentData[this.state.showLanguage].information.aboutAuthor[1]}</a> {contentData[this.state.showLanguage].information.aboutAuthor[2]} <a href="https://profoundstars.com/contact" target="_blank" rel="noopener noreferrer">{contentData[this.state.showLanguage].information.aboutAuthor[3]}</a></p>
          </div>
        </div>

      </div>
    );
  }
};


var rowNum = 0;
var screenwidth = window.screen.availWidth;
var condition = 0;

const calRowNum = () => {
  if (screenwidth >= 768) {
    rowNum = 15;
    condition = "condition_1";
  } else if (screenwidth <= 767 && screenwidth >= 679) {
    rowNum = 13;
    condition = "condition_2";
  } else if (screenwidth <= 678 && screenwidth >= 526) {
    rowNum = 10;
    condition = "condition_3";
  } else if (screenwidth <= 525 && screenwidth >= 351) {
    rowNum = 7;
    condition = "condition_4";
  } else if (screenwidth <= 350) {
    rowNum = 5;
    condition = "condition_5";
  }

  return rowNum;
};

calRowNum();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Game condition={ condition } rowNum={ rowNum } lang={ language } />);
