import React from 'react';

export default class Adsense_one_Gomokuupperbar extends React.Component {

  componentDidMount() {
   // (window.adsbygoogle = window.adsbygoogle || []).push({})
  // console.log(window.adsbygoogle);
   //let test = document.getElementById('root').getElementsByClassName('adsbygoogle');

  // console.log(test);
  }

  render () {
    return (
      <ins className="adsbygoogle" style={{backgroundColor: 'red'}}
      data-adtest="on"
      data-ad-client="ca-pub-7514506561137875"
      data-ad-slot="7401099790"
    //  data-ad-format="auto"
      data-full-width-responsive="true"></ins>
    )
  }
}


/*

const Adsense_one_Gomokuupperbar = () => {
  return (
    <amp-ad width="100vw" height="320"  style={{backgoroundColor: 'black'}}
        type="adsense"
        google_adtest="on"
        data-ad-client="ca-pub-7514506561137875"
        data-ad-slot="7401099790"
        data-auto-format="rspv"
        data-full-width="">
        <div overflow=""></div>
     </amp-ad>
  )
}


export { Adsense_one_Gomokuupperbar };
*/